var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.elitepage
        ? [
            _vm.authenticatedUser &&
            _vm.authenticatedUser.producer_account === _vm.elitepage.producer_id
              ? _c("router-view", { attrs: { name: "sidebar" } })
              : _vm._e(),
            _c("router-view", { attrs: { name: "navbar" } }),
            _c(
              "transition",
              { attrs: { name: "fade" } },
              [_c("router-view", { class: _vm.blurBackground })],
              1
            ),
            _c("router-view", {
              class: _vm.blurBackground,
              attrs: { name: "footer" },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }